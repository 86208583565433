// eslint-disable-next-line no-unused-vars
import React from "react";
import Facebook from "../assets/icons/Facebook.svg";
import Insta from "../assets/icons/Insta.svg";
import Linkedin from "../assets/icons/Linkedin.svg";
import { NavLink } from "react-router-dom";
// import { Link } from "react-scroll";
import { Link } from 'react-router-dom';
import { HashLink } from "react-router-hash-link";
function AboutUs() {
  const scrollDuration = 800;
  return (
    <>
      <div
        id="about"
        className="h-aboutmobile md:h-80 xl:h-aboutxl  flex flex-col md:flex-row sm:p-3 text-white p-5 "
      >
        <div className="md:w-2/5 h-2/6 md:px-2 flex-row p-1">
          <h1 className="text-sm font-bold  sm:text-4xl sm:py-3 text-white">About us </h1>
          <p className=" sm:pb-4 text-xs sm:px-0  lg:text-base  xl:text-lg  2xl:px-0 2xl:pl-0 ">
            Experience a truly Elevated and Hassle-Free Stay Experience with BetterMyStay App, designed to simplify every aspect of your journey. From the moment you arrive until you depart, we&rsquo;re dedicated to providing you with a Seamless and Personalized experience that redefines the art of Modern Hospitality.
            <br />
            <br />
            Registered Address ( India Office ) : A-211, A Block Road, Sector 122, Gautam Buddha Nagar, Noida, Uttar Pradesh (201301)
          </p>

        </div>
        <div className="mt-5 pt-4 px-1 font-semibold h-1/6 sm:h-1/6 sm:w-1/5 lg:translate-x-8 xl:mt-6 xl:translate-x-24">
        <ul className="flex flex-col space-x-4 sm:space-x-6 md:space-y-4 md:flex-col md:space-x-0 lg:text-xl justify-center items-center">
          <li className="hover:text-golden">
            <Link
              to="/bmshm/#about"
              spy={true}
              smooth="true"
              offset={-50}
              duration={500}
              className="hover:text-golden hover:cursor-pointer"
            >
              About
            </Link>
          </li>
          <li className="hover:text-golden">
            <HashLink
              to="/bmshm/#services"
              spy={true}
              smooth="true"
              offset={-50}
              duration={500}
              className="hover:text-golden hover:cursor-pointer"
            >
              Services
            </HashLink>
          </li>
          <li className="hover:text-golden">
            <HashLink
              to="/bmshm/#faq"
              spy={true}
              smooth="true"
              offset={-50}
              duration={500}
              className="hover:text-golden hover:cursor-pointer"
            >
              FAQs
            </HashLink>
          </li>
          <li className="hover:text-golden">
            <Link
              to="/bmshm/#enquiries"
              spy={true}
              smooth="true"
              offset={-100}
              duration={500}
              className="hover:text-golden hover:cursor-pointer"
            >
              Enquiries
            </Link>
          </li>
          <li className="hover:text-golden">
            <Link
              to="/bmshm/ContactUs"
              spy={true}
              smooth="true"
              offset={-100}
              duration={500}
              className="hover:text-golden hover:cursor-pointer"
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </div>

 <div className="mt-5 pt-4 px-1 font-semibold h-1/6 sm:h-1/6 sm:w-1/5 lg:translate-x-3 xl:mt-6 xl:translate-x-16">
<ul className="flex flex-col space-x-4 sm:space-x-6 md:space-y-4 md:flex-col md:space-x-0 lg:text-2xl w-full"> 
    <li className="hover:text-golden text-lg"> 
      <NavLink
        to="/bmshm/"
        spy={true}
        smooth={true}
        offset={-100}
        style={{ userSelect: "none" }}
        duration={scrollDuration}
        className="navb hover:text-white hover:bg-golden hover:rounded-2xl p-1 px-3"
      >
        Home
      </NavLink>
    </li>
    <li className="hover:text-golden text-xl"> 
      <NavLink
        to="/bmshm/TermsAndConditions"
        spy={true}
        smooth={true}
        offset={-100}
        style={{ userSelect: "none" }}
        duration={scrollDuration}
        className="navb hover:text-white hover:bg-golden hover:rounded-2xl p-1 px-3"
      >
        Terms and Conditions
      </NavLink>
    </li>
    <li className="hover:text-golden text-lg">  <NavLink
        to="/bmshm/PrivacyPolicy"
        spy={true}
        smooth={true}
        offset={-100}
        style={{ userSelect: "none" }}
        duration={scrollDuration}
        className="navb hover:text-white hover:bg-golden hover:rounded-2xl p-1 px-3"
      >
        Privacy Policy
      </NavLink>
    </li>
    <li className="hover:text-golden text-lg">  <NavLink
        to="/bmshm/RefundAndCancellation"
        spy={true}
        smooth={true}
        offset={-100}
        style={{ userSelect: "none" }}
        duration={scrollDuration}
        className="navb hover:text-white hover:bg-golden hover:rounded-2xl p-1 px-3"
      >
        Refund & Cancellation
      </NavLink>
    </li>
  </ul>
</div>
   

        <div className="h-1/6 pb-3 sm:w-1/5 flex-col align-middle justify-center md:mt-4 xl:mt-2 xl:px-9 xl:translate-x-10" >   
          <div style={{paddingTop:50}}></div>
          <ul
            className="md:px-10 md:space-y-4 flex flex-col space-x-4 sm:space-x-1 md:space-x-0"
          >
            <li className="h-7 w-7 rounded-full bg-zinc-700/60 hover:bg-golden sm:h-9 sm:w-9 md:h-10 md:w-10 xl:h-12 xl:w-12">
              <a style={{ userSelect: "none" }} href="#">
                <img
                  src={Facebook}
                  className="h-5 absolute ml-2 mt-1 p-0.5 sm:pt-1 sm:h-6 sm:pr-3 md:h-8 lg:h-9 lg:pr-3 lg:pb-2 xl:mt-1 xl:ml-2 sm:w-8 xl:h-12 xl:pb-2 xl:pr-1"
                />
              </a>
            </li>

            <li className="h-7 w-7 rounded-full bg-zinc-700/60 hover:bg-golden sm:h-9 sm:w-9 md:h-10 md:w-10 xl:h-12 xl:w-12">
              <a style={{ userSelect: "none" }} href="#">
                <img
                  src={Insta}
                  className="absolute h-5 sm:h-6 ml-1 mt-1 p-0.5 sm:pt-1 sm:w-9 sm:pr-3 md:h-8 md:pr-1 lg:h-9 lg:pt-0 lg:pr-2 lg:pb-1 xl:h-12 xl:pb-2 xl:pr-0"
                />
              </a>
            </li>

            <li className="h-7 w-7 rounded-full bg-zinc-700/60 hover:bg-golden sm:h-9 sm:w-9 md:h-9 md:w-10 xl:h-12 xl:w-12">
              <a style={{ userSelect: "none" }} href="#">
                <img
                  src={Linkedin}
                  className="absolute h-5 ml-1 mt-1 p-0.5 sm:pt-1 sm:w-8 sm:h-6 sm:pr-3 md:pr-1 lg:h-9 lg:pt-0 lg:pl-1 lg:pb-2 xl:mt-1 xl:h-12 xl:pb-2 xl:pr-0 xl:ml-2"
                />
              </a>
            </li>
          </ul>
        </div>
        <div
          className="w-full md:absolute
        md:bottom-10 lg:bottpm-16 lg:text-wrap   "
        >
          <br/>
          <h3 className="text-xs pt-4 md:px-2  text-white ">
            BetterMyStay™ is the registered trade name of Stay Connected Digitech
            Private Limited, a company incorporated under the provisions of the
            Companies Act, 2013, registered in India
          </h3>
          <p className="text-xs text-white pt-2 md:px-2">
            Curated with love by BetterMyStay 💛
          </p>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
