// // eslint-disable-next-line no-unused-vars
// import React from "react";

// // eslint-disable-next-line react/prop-types
// function HomeCard({ image, heading, description }) {
//   return (
//     <div className=" bg-CardColor h-72 w-48 rounded-xl shadow-xl hover:shadow-top-left-bottom sm:h-64 sm:w-52 sm:overflow-hidden md:w-52 lg:w-56  xl:h-80 xl:w-60 ">
//       <div className="justify-center flex">
//         <div className=" h-12 w-12 m-5 flex justify-center items-center rounded-full bg-yellow-500/65 sm:h-9 sm:w-9 sm:py-1 sm:px-0 sm:m-5 xl:m-10 xl:h-14 xl:w-14 2xl:m-8">
//           <img src={image} className="h-8 sm:h-6  xl:h-9"></img>
//         </div>
//       </div>
//       <h1 className="text-base text-left px-3  font-semibold sm:text-base md:text-sm lg:text-center xl:px-4 xl:text-lg xl:mb-3 2xl:mt-0 ">
//         {heading}
//       </h1>
//       <p className="text-left text-sm text-gray-600/75 font-medium px-3 sm:text-xs xl:px-6 xl:translate-x-2 xl:text-base tracking-tight  ">
//         {description.split("\n").map((line, index) => (
//           <span key={index}>
//             {line}
//             <br />
//           </span>
//         ))}
//       </p>
//     </div>
//   );
// }

// export default HomeCard;

import React from 'react'; // Import PropTypes



function HomeCard({ image, heading, description }) {
  return (
    <div className="bg-CardColor h-72 w-48 rounded-xl shadow-xl hover:shadow-top-left-bottom sm:h-64 sm:w-52 sm:overflow-hidden md:w-52 lg:w-56 xl:h-80 xl:w-60">
      <div className="justify-center flex">
        <div className="h-12 w-12 m-5 flex justify-center items-center rounded-full bg-yellow-500/65 sm:h-9 sm:w-9 sm:py-1 sm:px-0 sm:m-5 xl:m-10 xl:h-14 xl:w-14 2xl:m-8">
          <img src={image} className="h-8 sm:h-6 xl:h-9" alt={heading} /> {/* Add alt attribute */}
        </div>
      </div>
      <h1 className="text-base text-left px-3 font-semibold sm:text-base md:text-sm lg:text-center xl:px-4 xl:text-lg xl:mb-3 2xl:mt-0">
        {heading}
      </h1>
      <p className="text-left text-sm text-gray-600/75 font-medium px-3 sm:text-xs xl:px-6 xl:translate-x-2 xl:text-base tracking-tight">
        {description.split("\n").map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))}
      </p>
    </div>
  );
}



export default HomeCard;
