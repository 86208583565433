// eslint-disable-next-line no-unused-vars
import React  from "react";

function card({ heading, body }) {
  let tabSpace="     "
  return (
    <div className=" text-white  ">
      <h1
        className="font-bold text-left
      lg:text-xl 2xl:text-2xl"
      >
        {heading}
      </h1>
      <br/>
      <p className="font-normal text-left xl:text-lg 2xl:text-base">

        {body.split('\n').map((line, index) => ( 
        <p key={index}>
          {line.startsWith('•') ? (`${tabSpace.concat(line)}`) : (`${line}\n`)}
        </p>
        ))}
      </p>
    </div>
  );
}

export default card;
