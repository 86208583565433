const policy = [
  {
    heading: "Privacy Policy",
    body: "This Privacy Policy governs the manner in which BetterMyStay™, a trade name of Stay Connected Digitech Pvt Ltd ('BetterMyStay', 'we', 'us' or 'our'), collects, uses, maintains, and discloses information collected from users (referred to as 'you' or 'your') of the BetterMyStay Software (collectively referred to as the 'Service'). This Privacy Policy applies to the Service and all products and services offered by BetterMyStay.",
  },
  {
    heading: "Information We Collect",
    body: "Personal Information: When you use our Service, we may collect personal information such as your name, email address, phone number, and payment details. We may also collect information about your stay preferences and history.KYC Information: As part of our service, we may collect Know Your Customer (KYC) information such as government-issued ID proof, address proof, and other relevant documents to verify your identity. Usage Information: We may collect information about how you use the Service, including log data, device information, and location information if you enable location services.",
  },
  {
    heading: "How We Use Information",
    body: "Providing Services: We use the collected information to provide and improve our services, personalize your experience, process transactions, and manage your account. KYC Verification: KYC information is collected to comply with legal requirements and to verify your identity for using our services.Communications: We may use your contact information to send you updates, newsletters, promotional offers, and other relevant communications. Analytics: We may use cookies and similar technologies to analyze trends, track user activities, and gather demographic information for improving our services.",
  },
  {
    heading: "Data Security",
    body: "BetterMyStay is committed to protecting your personal information from loss, theft and misuse. We take all reasonable precautions to safeguard the confidentiality of your personal information, including through use of appropriate organisational and technical measures. We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security. In the course of provision of your personal information to us, your personal information may be transferred over the internet. Although we make every effort to protect the personal information which you provide to us, the transmission of information between you and us over the internet is not completely secure. As such, you acknowledge and accept that we cannot guarantee the security of your personal information while it is transmitted in this way and that any such transmission is at your own risk. Once we have received your personal information, we will use strict procedures and security features to prevent unauthorised access to it. Where we have given you (or where you have chosen) a password which enables you to access our app, you are responsible for keeping this password confidential. We require you not to share a password with anyone. All the data we are collecting is stored encrypted on secured Indian Servers.",
  },
  {
    heading: "Sharing of Information",
    body: "We may share your information with third-party service providers, business partners, or regulatory authorities as required by law or to fulfill the purposes mentioned in this Privacy Policy. We do not sell, trade, or rent your personal information to third parties for marketing purposes.Our software may include social media links, such as the Facebook, Twitter, Instagram or LinkedIn “sharing” or “follow” buttons; and may contain links and pages to other websites operated by third parties. We cannot be responsible for personal information that third parties may collect, store and use through third party features and websites. Please note that this Privacy Notice applies only to the personal information that we collect through our software.",
  },
  {
    heading: "Your Choices",
    body: "You can choose not to provide certain information, but it may limit your ability to use certain features of the Service. You can also update or delete your information by contacting us at connect@bettermystay.com.",
  },
  {
    heading: "Changes to This Privacy Policy",
    body: "We reserve the right to update or change this Privacy Policy at any time. Any changes we make to our Privacy Notice in the future will be posted on this page and, where appropriate, notified to you by post or email. Please check back frequently to see any updates or changes to our Privacy Notice.",
  },
  {
    heading: "Last Updated : 16th September 2024",
    body: "",
  },
];
export default policy;
