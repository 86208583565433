/* eslint-disable no-unused-vars */
import React from "react";
import Logopic from "../assets/images/OldLogo.svg"
import { Link } from 'react-router-dom';

function Logo() {
  return (
    <div className="text-sm mt-1 ml-2 flex-row  w-36 p-1 h-12 text-center rounded-xl  bg-white/35  sm:p-1  sm:w-48 sm:mx-10 sm:h-10  sm:text-lg md:h-14 md:w-36 md:text-base md:ml-12 md:mx-1 lg:h-10 lg:w-48 lg:ml-16  xl:w-56 xl:ml-20 xl:h-12  2xl:ml-32 ">
      
      <Link  scroll={true} style={{userSelect:"none"}}to="/">
      <p className="inline-block text-white  font-normal font-sans xl:text-2xl">
        Better My<span className="text-yellow-500">Stay</span>
      </p>
      </Link>

      

      <img
        src={Logopic}
        alt="Logo"
        className="inline-block ml-1 h-10 w-8 object-contain md:h-12 md:mb-2 md:w-8"
        // className="inline-block ml-1 h-10 w-8 object-contain md:h-14 md:mb-2 md:w-8"

      />
    </div>
  );
}

export default Logo;

