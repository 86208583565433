/* eslint-disable no-unused-vars */
import React from "react";
import Logo from "./Logo";
import Nav from "./Nav";

const Header = () => {
  return (
    <header
      id="header"
      className="absolute top-3 z-[40] mx-auto flex w-full flex-wrap items-center justify-between "
    >
      <Logo></Logo>
      <Nav></Nav>
    </header>
  );
};

export default Header;
