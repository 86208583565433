import "./App.css";
import { BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import Home from "./screens/Home";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TermsAndConditions from "./screens/TermsAndConditions";
import RefundPolicy from "./screens/RefundPolicy";
import DeleteUser from "./screens/DeleteUser";
import ComingSoon from "./screens/ComingSoon";
import ContactUs from "./screens/ContactUs";
import CancellationPolicy from "./screens/CancellationPolicy";
import RefundAndCancellationPolicy from "./screens/RefundAndCancellationPolicy";
import Pricing from "./screens/Pricing"


function App() {

  return (
    <>
      <BrowserRouter>
        <div className="app">
         
          <Routes>
           
            <Route path="/" element={<ComingSoon />} />
            <Route path="/bmshm" element={<Home />} />
            <Route path="/comingsoon" element={<ComingSoon />} />
            <Route path="/bmshm/TermsAndConditions" element={<TermsAndConditions />} />
            <Route path="/bmshm/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/bmshm/RefundPolicy" element={<RefundAndCancellationPolicy />} />
            <Route path="/bmshm/CancellationPolicy" element={<RefundAndCancellationPolicy />} />
            <Route path="/bmshm/RefundAndCancellation" element={<RefundAndCancellationPolicy />} />
            <Route path="/bmshm/DeleteUser" element={<DeleteUser />} />
            <Route path="/bmshm/Pricing" element={<Pricing />} />
            <Route path="/bmshm/ContactUs" element={<ContactUs />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
