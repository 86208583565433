const terms = [
  {
    heading: "Terms and Conditions",
    body: "These Terms and Conditions ('Terms') govern your use of the BetterMyStay™ Software (collectively referred to as the 'Service'), which is operated by Stay Connected Digitech Pvt Ltd ('BetterMyStay', 'we', 'us', or 'our'). By accessing or using the Service, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you may not use the Service.",
  },
  {
    heading: "Use of Service",
    body: "Use of Service You must be at least 18 years old and have the legal capacity to enter into agreements to use the Service. By using the Service, you represent and warrant that you meet these eligibility requirements.  License:We grant you a limited, non-exclusive, non-transferable, revocable license to use the Service for personal and non-commercial purposes. If you are a seller on our marketplace, you may use the Service to list, sell, and manage your listings in accordance with our Seller Terms and Policies.",
  },
  {
    heading: "Account Registration",
    body: "Account Creation: To access certain features of the Service, you may be required to create an account and provide accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.Seller Account: If you are a seller on our marketplace, you agree to comply with our Seller Terms and Policies, which govern the sale, fulfilment, and returns of products or services through the Service.",
  },
  {
    heading: "Privacy",
    body: "Your use of the Service is subject to our Privacy Policy, which governs the collection, use, and disclosure of your personal information. By using the Service, you consent to the terms of the Privacy Policy.",
  },
  {
    heading: "User Content",
    body: "Ownership: You retain ownership of any content, including text, images, and other materials, that you submit or upload to the Service . By submitting User Content, you grant us a worldwide, royalty-free, non-exclusive license to use, reproduce, modify, adapt, publish, translate, distribute, and display the User Content for the purpose of providing and improving the Service. Prohibited Content: You agree not to submit or upload any User Content that is illegal, infringes upon third-party rights, is defamatory, obscene, or otherwise objectionable.",
  },
  {
    heading: "Intellectual Property",
    body: "The Service and its content, including but not limited to text, graphics, logos, icons, images, audio clips, and software, are the property of BetterMyStay or its licensors and are protected by intellectual property laws. You may not use, copy, reproduce, modify, distribute, or create derivative works of the Service without our prior written consent.",
  },
  {
    heading: "Limitation of Liability",
    body: "Disclaimer: The Service is provided on an as is and as available basis without warranties of any kind, whether express or implied. We do not warrant that the Service will be uninterrupted, error-free, or secure.Limitation of Liability: In no event shall BetterMyStay, its officers, directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Service.",
  },
  {
    heading: "Governing Law",
    body: "These Terms shall be governed by and construed in accordance with the laws of Uttar Pradesh. Any disputes arising under these Terms shall be subject to the exclusive jurisdiction of the courts located in Uttar Pradesh, India.",
  },
  {
    heading: "Changes to Terms",
    body: "We reserve the right to update or modify these Terms at any time without prior notice. Any changes will be effective immediately upon posting the updated Terms on the Service. Your continued use of the Service after such changes constitutes your acceptance of the revised Terms.",
  },
  {
    heading: "Last Updated : 16th September 2024",
    body: "",
  },
];
export default terms;
