// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import Genie from "../assets/images/GenieDownload.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Enquiry() {

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [question, setQuestion] = useState("");


  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      name,
      email,
      phone,
      question,
    };
      
    // send to backend
    fetch("https://stage-api.bettermystay.com/api/v1/contactus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "5f4dcc3b5aa765d61d8327deb882cf99"
      },
      body: JSON.stringify({  // Convert the object to a JSON string
        'email': email || "",
        'phone': phone || "",
        'name': name,
        'orgName': "",
        'plan': "",
        'message': question,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from server:", data);
        if (data.message ==='Added!') {
          setName("")
          setPhone("")
          setEmail("")
          setQuestion("")
          
          toast.success("Thanks for Contacting Us. Our team will soon get back to you! ");
        }
      })
      .catch((error) => {
        console.error("Error sending request:", error);
        toast.error("An error occurred. Please Try Again.");
      });
  
  };

  return (
    <>
      <div
        id="enquiries"
        //  id="contact"
        className="flex flex-col sm:flex-row h-contactmobile md:h-full  sm:h-80 w-full "
      >
        <div className="h-56 items-center justify-center sm:h-full sm:w-1/2 flex sm:items-center sm:justify-center">
          <div className="absolute 2xl:left-36 left-14 lg:left-5 xl:left-36 ">
            <img src={Genie} className="h-32 sm:h-56 lg:h-96 2xl:h-genie"></img>
          </div>
          <button
            className="h-8  w-28  text-white font-semibold bg-golden rounded-lg 
            translate-x-4  hover:text-golden md:h-10 md:translate-x-10 lg:h-16
          lg:w-44 lg:translate-x-9 xl:translate-x-24 2xl:w-44 2xl:h-16 2xl:text-2xl 2xl:mr-1 2xl:translate-x-20 "
          >
            ENQUIRIES
          </button>
        </div>
        <div className="h-5/6 sm:h-full sm:w-1/2 flex justify-center align-middle items-center">
          <form style={{height:500}} onSubmit={handleSubmit} className="p-3 sm:space-y-2 justify-center flex flex-col sm:m-6 bg-golden/50 md:px-7 md:h-72 lg:px-10  lg:h-80 2xl:h-5/6 rounded-2xl">
            <div className=" flex justify-center space-x-2">
              <div>
                <label htmlFor="name" className="block text-white">
                  Name
                </label>

                <input
                  type="text"
                  value={name}
                  className="w-56 sm:w-60 xl:w-80 h-8 rounded-md p-2"
                  placeholder="Your good name "
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </div>
            </div>

            <div className=" flex justify-center space-x-2">
              <div>
                <label htmlFor="name" className="block text-white">
                  Phone Number
                </label>

                <input
                  type="text"
                  value={phone}
                  className="w-56 sm:w-60 xl:w-80 h-8 rounded-md p-2"
                  placeholder="Your Contact Number "
                  onChange={(e) => setPhone(e.target.value)}
                ></input>
              </div>
            
            </div>
            <div className="flex justify-center space-x-2 ">
              <div>
                <label htmlFor="name" className="block text-white">
                  Email
                </label>

                <input
                  type="email"
                  value={email}
                  className="w-56 sm:w-60 xl:w-80 h-8 rounded-md p-2"
                  placeholder="Where can we contact you "
                  onChange={(e) => setEmail(e.target.value)}
                   
                ></input>
              </div>
            </div>
            <div className="flex  justify-center space-x-2 ">
              <div>
                <label htmlFor="name" className="block text-white">
                  Your Question
                </label>

                <textarea
                 value={question}
                  className="w-56 sm:w-60 xl:w-80 h-16 rounded-md p-2 resize-none"
                  placeholder="We would love to hear to answer all your Questions "
                  onChange={(e) => setQuestion(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="flex justify-center w-full xl:pt-6 ">
              <button
                type="submit"
                className="bg-golden  w-28 lg:h-10 xl:h-10
                xl:w-32 2xl:h-10 rounded-full text-white hover:bg-white hover:text-golden"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "5rem" }}
      />
      </div>
    </>
  );
}

export default Enquiry;
