// eslint-disable-next-line no-unused-vars
import React from "react";
import Card from "../components/card";
import terms from "../assets/TermsCond";
import "./container.css";
import { useEffect } from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";


function TermsAndConditions() {

  useEffect(() => {
    const element = document.documentElement;
    element.style.scrollBehavior = 'smooth';
    element.scrollTo(0, 0);
  }, []);
  
  return (
    <>
    <Header/>
      <div className="bg-container">
        <div className="bg-image"></div>
        <div
          className="bg-text flex-col absolute top-20 bg-golden/30 p-2 m-8 
          md:mx-24
          lg:mx-28
          xl:mx-36 
          2xl:mx-56 "
        >
          <div className="text-center">
            <h1 className="text-base text-white sm:text-4xl font-bold xl:text-5xl pt-5">
              Terms and Conditions
            </h1>
          </div>

          <div className="p-3 md:p-16 lg:p-12 xl:p-16 xl:space-y-16 2xl:p-20  space-y-5 md:space-y-5 ">
            {terms.map(({ id, heading, body }) => (
              <Card key={id} heading={heading} body={body} style={{ heading: { fontSize: '1.2rem' }, body: { fontSize: '1.1rem' } }}></Card>
            ))}
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default TermsAndConditions;
