const data = [
  {
    heading: "",
    body: "This Refund And Cancellation Policy applies to refunds and cancellations of orders made through our platform, operated by Stay Connected Digitech Pvt Ltd ( 'BetterMyStay', 'we', 'us' or 'our'). Our platform acts as a facilitator, connecting hotels and resorts with their guests, and enabling them to list their paid services. Hotels and resorts are solely responsible for providing the services listed on our platform and for processing cancellations related to those services.",
  },
  {
    heading: "Scope of Policy",
    body: "This policy applies to all services listed and ordered through our platform, including all amenities provided by our hotel and resort partners.",
  },
  {
    heading: "Refund Eligibility",
    body: `Refunds are available for products or services purchased through our platform that meet the following criteria:
• The service provided did not meet the agreed-upon terms and conditions.
• The service was not delivered as promised by the hotel or resort.
• The service was defective or significantly different from what was described in the listing.`
  },
  {
    heading: "Refund Ineligibility",
    body: `Refunds are not available for these products or services purchased through our platform that meet the following criteria:
• Digital products or services that have been accessed or downloaded.
• Customized or personalized items that cannot be resold.
• Services consumed by the guest partially or in full.
• Subscription fees paid by hotels and resorts to list on our platform (non-refundable).`,
  },

  {
    heading: "Cancellation Eligibility",
    body: `Cancellations are allowed under the following circumstances:
  • The Guest is unable to utilize the ordered service due to Unforeseen Circumstances.
  • The Hotel or Resort is unable to provide the ordered service.
  • The service was significantly different from what was described in the listing.`,
  },
  {
    heading: "Cancellation Ineligibility",
    body: `Cancellations are not allowed under the following circumstances:
  • The guest has already consumed the ordered service partially or in full.
  • The guest has failed to provide required documentation or information.
  • The cancellation is made after the specified time period.`,
  },

  {
    heading: "Refund Process ",
    body: `Initiating a Refund: Guests can request a refund by contacting the hotel or resort directly.
    Hotels and resorts are responsible for processing refunds for eligible items.
    Guests must provide a detailed explanation of the issue and any supporting evidence, such as photos or receipts, and have to submit the refund request within a reasonable timeframe.
   \n Hotel/Resort Responsibility: Hotels and resorts are required to respond to refund requests promptly and process refunds in accordance with their refund policy.
Hotels and resorts must communicate with guests regarding the status of their refund requests.`,
  },
  {
    heading: "Cancellation Process",
    body: `Initiating a Cancellation: Guests can request a cancellation by contacting the hotel or resort directly.
  Hotels and resorts are responsible for processing cancellations and refunding any applicable amounts.
  Guests must provide a detailed explanation of the reason for cancellation and any supporting evidence.`,
  },
  {
    heading: "Cancellation Fees",
    body: `Cancellation fees may apply, depending on the hotel or resort's policies.
  Guests will be notified of any applicable cancellation fees at the time of ordering.
  Cancellation fees will be deducted from the refund amount, if applicable.`,
  },
  {
    heading: "Refund Timeframe",
    body: `Processing Time:Refunds should be processed by the hotel or resort within 5-7 working days from the date of approval.
Hotels and resorts are encouraged to process refunds as quickly as possible to ensure guest satisfaction.
\nNotification: Guests will be notified via email or through our platform once their refund request is approved and processed by the hotel or resort`,
  },
  {
    heading: "Notice Periods",
    body: `Guests must provide prior notice of cancellation prior to the scheduled start time of the service to avoid any cancellation fees.`,
  },
  {
    heading: "Guest Responsibility",
    body: `Guests are responsible for:
  • Providing accurate and complete information when making a Order / Booking.
  • Notifying the hotel or resort of any changes or cancellations in a timely manner.
  • Paying any applicable cancellation fees.`,
  },
  {
    heading: `Subscription Fees`,
    body: `Subscription fees paid by hotels and resorts to list on our platform are non-refundable.`,
  },

  {
    heading: "Last Updated : 18th September 2024",
    body: "",
  },
];
export default data;
