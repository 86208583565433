const data = {
  title: "Frequently Asked Questions's",
  rows: [
    {
      title: "How does BetterMyStay Improve my Stay?",
      content:
        "At BetterMyStay, We are redefining the hospitality experience through our mobile application. Our mission is to provide seamless, convenient, and personalized stays for hotel guests by state of the art technologies . Our app serves as a comprehensive digital companion, empowering the guests from the time they check-in to the time they check-out.  ",
    },
    {
      title: "Can we Order Food or make book anything anywhere in the Hotel? ",
      content:
        "Yes, you can Order Food, Book Spa or schedule a Housekeeping Service itelsf in the BetterMyStay App anywhere within the hotel premises.",
    },
    {
      title: "How Sleamless is the Check In and CheckOut Journey?",
      content:
        "After your KYC is done, Check In can be Done by just verifying your Selfie and Check Out can be done on just click of a button. ",
    },
    {
      title: "Can we pay make Payments from the BetterMyStay App ?",
      content: "Yes, you can make payments on your fingertips in the BetterMyStayApp.",
    },
  ],
};
export default data;
