import person1 from "./images/person1.jpg";
import person2 from "./images/person2.jpg";
import person3 from "./images/person3.jpg";
import person4 from "./images/person.jpg";

const reviews = [
  {
    id: 1,
    Profile: person1,
    Name: "Jai Aggrawal",
    Comment:
      " This App is a total game-changer for hotel stays! BetterMyStay made everything so easy!!!. From Check In via mobile to ordering Food and Room Service. Genius! ",
    rating: 1,
  },
  {
    id: 2,
    Profile: person2,
    Name: "Shreya Desai",
    Comment:
      "As someone who travels a ton for work, BetterMyStay has genuinely improved my Stay Experience. The tech is so smart and caters to everything I need. ",
    rating: 2,
  },
  {
    id: 3,
    Profile: person3,
    Name: "Sakshi Sharma",
    Comment:
      "Finally, an App that puts the Guest's first! BetterMyStay gave me a seamless, hassle-free stay from start to finish. Kudos to this product.",
    rating: 3,
  },
  {
    id: 4,
    Profile: person4,
    Name: "Ananay Gupta",
    Comment:
      "Where has BetterMyStay been all my life? Mobile Ordering, Sleamless Stays, Booking Room Service - This App nailed the Modern Hotel Experience.",
    rating: 4,
  },
];
export default reviews;
