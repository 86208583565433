/* eslint-disable no-unused-vars */
import React from "react";
import star4 from "../assets/images/ratings/stars4.svg";
import star45 from "../assets/images/ratings/stars45.svg";

// eslint-disable-next-line react/prop-types
function ReviewCard({ image, name, comment, rating }) {
  return (
    <div
      className=" bg-white w-auto h-64 space-y-1 flex flex-col  border-2 border-slate-500/35 sm:h-72 sm:w-40  md:h-80
    md:w-72 lg:h-80 lg:w-80 xl:h-80
    xl:w-11/12 2xl:h-review 2xl:w-80 hover:shadow-2xl  "
    >
      <div className="h-20 w-full p-1 m-1 justify-center flex md:p-2   md:m-2  ">
        <img
          src={image}
          className="rounded-full h-14 w-14 md:h-16 md:w-16 "
        ></img>
      </div>
      <div className="h-32 w-full flex-col  sm:h-40 md:h-44 ">
        <h3 className="font-bold text-black text-base sm:text-base  md:text-xl md:p-1 md:m-1 ">
          {name}
        </h3>
        <p className="text-xs p-3 sm:text-xs md:text-base md:py-1 ">
          {comment}
        </p>
      </div>
      <div className="h-7  flex justify-center w-full sm:h-6 md:h-10 md:p-2  ">
        {rating === 1 && <img src={star4} alt="Rating 1"></img>}
        {rating === 2 && <img src={star45} alt="Rating 2"></img>}
        {rating === 3 && <img src={star4} alt="Rating 3"></img>}
        {rating === 4 && <img src={star45} alt="Rating 4"></img>}
        {rating === 5 && <img src={star4} alt="Rating 5"></img>}
      </div>
    </div>
  );
}

export default ReviewCard;
