import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./container.css";
import bcrypt from "bcryptjs";
import Header from "../components/Header";
import Footer from "../components/Footer";


function DeleteUser() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [userExists, setUserExists] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  //const [passwordVerified, setPasswordVerified] = useState(false);
  const [showOtpInputs, setShowOtpInputs] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [passwordLogin, setPasswordLogin] = useState(false);
  const [enteredPassword, setEnteredPassword] = useState("");

  const handleVerifyUser = () => {
    if (email === "rachitap89@gmail.com" || mobile === "6398624260") {
      setUserExists(true);
      toast.success("User exists. Please login.");
      setShowOtpInputs(true);
    } else {
      setUserExists(false);
      toast.error("User not found.");
    }
  };

  useEffect(() => {
    const element = document.documentElement;
    element.style.scrollBehavior = 'smooth';
    element.scrollTo(0, 0);
  }, []);

  const handleLoginWithEmail = () => {
    console.log("Login with Password button clicked");
    setPasswordLogin(true);
  };

  const handleLoginWithMobile = () => {
    sendOtp();
  };

  const sendOtp = () => {
    setOtpSent(true);
    toast.info("OTP sent to mobile");
  };

  const handleOtpVerification = () => {
    setOtpVerified(true);
    toast.success("OTP verified. Logging in...");
  };

  const handlePasswordVerification = () => {
    bcrypt.hash(enteredPassword, 10, (err, hashedPassword) => {
      if (err) {
        console.error("Error hashing password:", err);
        toast.error("An error occurred. Please try again.");
        return;
      }

      //send to backend
      // fetch("http://localhost:3001/users", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ hashedPassword }),
      // })
      //   .then((response) => response.json())
      //   .then((data) => {
      //     console.log("Response from server:", data);
      //     if (data.success) {
      //  setPasswordVerified(true);
      //       toast.success("Password verified and sent to backend.");
      //     } else {
      //       toast.error("Error sending password to backend.");
      //     }
      //   })
      //   .catch((error) => {
      //     console.error("Error sending request:", error);
      //     toast.error("An error occurred. Please try again.");
      //   });

      console.log("Hashed Password:", hashedPassword);

      toast.success("Password hashed successfully.");

      setEnteredPassword("");
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleVerifyUser();
  };

  return (
    <>
    <Header/>
    <div className="registerBg-container bg-golden/70">
      <h1 className="max-w-md translate-y-32 mx-auto p-3 bg-white shadow-md rounded-lg mb-4 text-center font-bold text-2xl">
        Delete User
      </h1>
      <div className="max-w-md translate-y-32 mx-auto p-6 bg-white shadow-md rounded-lg">
        <h2 className="text-2xl font-bold mb-4">User Verification</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="mobile"
              className="block text-sm font-medium text-gray-700"
            >
              Mobile Number
            </label>
            <input
              type="tel"
              id="mobile"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter your mobile number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-BMSBlue2 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Verify
            </button>
          </div>
        </form>
        {userExists && showOtpInputs && !otpVerified && (
          <div className="mt-4">
            <p className="text-green-600">Please verify with OTP:</p>
            {!otpSent && (
              <div className="mt-2 space-y-4">
                <button
                  className="bg-BMSBlue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={handleLoginWithEmail}
                ><div className="registerBg-container bg-golden/70">
                <h1 className="max-w-md translate-y-32 mx-auto p-3 bg-white shadow-md rounded-lg mb-4 text-center font-bold text-2xl">
                  Delete User
                </h1>
                <div className="max-w-md translate-y-32 mx-auto p-6 bg-white shadow-md rounded-lg">
                  <h2 className="text-2xl font-bold mb-4">User Verification</h2>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Enter your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="mobile"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Mobile Number
                      </label>
                      <input
                        type="tel"
                        id="mobile"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Enter your mobile number"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        required
                      />
                    </div>
                    <div className="flex items-center justify-between">
                      <button
                        type="submit"
                        className="bg-BMSBlue2 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      >
                        Verify
                      </button>
                    </div>
                  </form>
                  {userExists && showOtpInputs && !otpVerified && (
                    <div className="mt-4">
                      <p className="text-green-600">Please verify with OTP:</p>
                      {!otpSent && (
                        <div className="mt-2 space-y-4">
                          <button
                            className="bg-BMSBlue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            onClick={handleLoginWithEmail}
                          >
                            Login with Password (Email)
                          </button>
                          {!passwordLogin && (
                            <button
                              className="bg-BMSBlue hover:bg-blue-600 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline"
                              onClick={handleLoginWithMobile}
                            >
                              Login with OTP (Mobile)
                            </button>
                          )}
                        </div>
                      )}
                      {otpSent && (
                        <div className="mt-2">
                          <label
                            htmlFor="otp"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Enter OTP
                          </label>
                          <input
                            type="text"
                            id="otp"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Enter OTP"
                          />
                          <button
                            className="mt-2 bg-BMSBlue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            onClick={handleOtpVerification}
                          >
                            Verify OTP
                          </button>
                        </div>
                      )}
                      {passwordLogin && (
                        <div className="mt-2">
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Enter Password
                          </label>
                          <input
                            type="password"
                            id="password"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Enter Password"
                            value={enteredPassword}
                            onChange={(e) => setEnteredPassword(e.target.value)}
                          />
                          <button
                            className="mt-2 bg-BMSBlue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            onClick={handlePasswordVerification}
                          >
                            Verify Password
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  style={{ marginTop: "5rem" }}
                />
              </div>
                  Login with Password (Email)
                </button>
                {!passwordLogin && (
                  <button
                    className="bg-BMSBlue hover:bg-blue-600 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline"
                    onClick={handleLoginWithMobile}
                  >
                    Login with OTP (Mobile)
                  </button>
                )}
              </div>
            )}
            {otpSent && (
              <div className="mt-2">
                <label
                  htmlFor="otp"
                  className="block text-sm font-medium text-gray-700"
                >
                  Enter OTP
                </label>
                <input
                  type="text"
                  id="otp"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter OTP"
                />
                <button
                  className="mt-2 bg-BMSBlue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={handleOtpVerification}
                >
                  Verify OTP
                </button>
              </div>
            )}
            {passwordLogin && (
              <div className="mt-2">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Enter Password
                </label>
                <input
                  type="password"
                  id="password"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter Password"
                  value={enteredPassword}
                  onChange={(e) => setEnteredPassword(e.target.value)}
                />
                <button
                  className="mt-2 bg-BMSBlue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={handlePasswordVerification}
                >
                  Verify Password
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "5rem" }}
      />
    </div>
    <Footer/>
    </>
  );
}

export default DeleteUser;
