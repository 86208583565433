/* eslint-disable no-unused-vars */
import React from "react";

import AboutUs from "./AboutUs";
import FooterImage from "../assets/images/FooterImage.jpg";

function Footer() {
  return (
    <>
      <footer
        className="overflow-hidden
        absolute bottom-0 z-[20] flex w-full  justify-center bg-slate-300 h-footermobile px-4 md:h-footertablet lg:h-footerlaptop"
        style={{
          backgroundImage: `url(${FooterImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/*About Us*/}
        <AboutUs></AboutUs>
      </footer>
    </>
  );
}

export default Footer;
