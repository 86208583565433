// SubscriptionPlans.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import check_select_subscription from "../assets/images/check_select_subscription.png";
import { toast, ToastContainer } from "react-toastify";
import Header from "../components/Header";
import Footer from "../components/Footer"
import { useNavigate } from 'react-router-dom';
import "./container.css";
const Pricing = () => {

  const navigate = useNavigate();
  const [flow, setFlow] = useState('Pricing');
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPlanDetails, setSelectedPlanDetails] = useState(null);

  
  useEffect(() => {
    setSelectedPlan("6 Months");
    setSelectedPlanDetails({
        "tax": {
            "taxType": "GST",
            "percent": 18
        },
        "subscriptionId": "SUB-0R68DGT6",
        "name": "Semi-Annually",
        "durationInDays": 180,
        "pricePerRoom": 499,
        "description": "Most Popular",
        "discount": 10,
        "subType": "6 Months"
    })
  }, [])


  useEffect(()=>{
    setPlans([
        {
            "tax": {
                "taxType": "GST",
                "percent": 18
            },
            "subscriptionId": "SUB-2TRLEJ69",
            "name": "Annually",
            "durationInDays": 365,
            "pricePerRoom": 499,
            "description": "Best Value",
            "discount": 20,
            "subType": "Yearly"
        },
        {
            "tax": {
                "taxType": "GST",
                "percent": 18
            },
            "subscriptionId": "SUB-0R68DGT6",
            "name": "Semi-Annually",
            "durationInDays": 180,
            "pricePerRoom": 499,
            "description": "Most Popular",
            "discount": 10,
            "subType": "6 Months"
        },
        {
            "tax": {
                "taxType": "GST",
                "percent": 18
            },
            "subscriptionId": "SUB-HB4ADLKG",
            "name": "Quarterly",
            "durationInDays": 90,
            "pricePerRoom": 499,
            "description": "Starters Pack",
            "discount": 0,
            "subType": "3 Months"
        },
        {
            "tax": {
                "taxType": "GST",
                "percent": 18
            },
            "subscriptionId": "SUB-HB4ADLKG",
            "name": "Custom Subscription",
            "durationInDays": 0,
            "pricePerRoom": "",
            "description": "Custom" ,
            "discount": "Custom",
            "subType": "Custom"
        }
    ]);
  }, []);

  

  const handleDiscountedPrice = (pricePerRoom, discount) => {
    var calculate = pricePerRoom - (pricePerRoom * (discount / 100 ));
    var integerPricing = Math.floor(calculate);

    if ( integerPricing <= 49) {
      integerPricing = 49;
    }
    else if (integerPricing <= 99) {
      integerPricing = 99;
    }
    else {
      var rem = integerPricing % 100;
      var div = Math.floor(integerPricing / 100);

      if (rem <= 49) {
        integerPricing = (div * 100) + 49;
      }
      else {
        integerPricing = (div * 100) + 99;
      }
    }
    return integerPricing;
  }


  const handleContinueButton = (e) => {
    e.preventDefault();
    console.log(flow, selectedPlanDetails,selectedPlan)

        if (selectedPlan){
        navigate('/bmshm/ContactUs', { state: {flow, selectedPlanDetails}  });
        }else{
          toast.error("Please select a Subscription Type First",)
        }
      }
    

      const handleDemoButton = (e) => {
        e.preventDefault();
        navigate('/bmshm/ContactUs', { state: {flow}  });
        }

  


 
  const handleChooseSubscription = (e, plan) => {
    e.preventDefault();

    
        setSelectedPlanDetails(plan);
        setSelectedPlan(plan.subType);
    
    
  }
  
  
  return (
    <>
        <Header />
        <div className='pricing-container'> 
        <div style={{paddingTop:100}}>
            
                <h4 className="subscriptionModelheading">Flexible Plans</h4>

                <p className="subscriptionModelpara"> Choose a plan that works the best for you and your Team. </p>

                <div className="subscriptionPlans">
                    {plans.map((plan) => (
                    <div
                        key={plan.subType}
                        className = { selectedPlan === plan.subType ? 
                          "subscriptionplanBox subscriptionPlanSelected" :
                          "subscriptionplanBox"}
                        onClick={(e) => handleChooseSubscription(e, plan)}
                    >
                        {selectedPlan === plan.subType && (
                        <img
                            src={check_select_subscription}
                            style={{
                            height: "50px",
                            position: "absolute",
                            top: "-15px", 
                            right: "110px", 
                            }}
                            alt="Selected Plan"
                        />
                        )}
                        <h5 className="subscriptionPlanBoxheading">{plan.name}</h5>
                        {plan.subType!=="Custom" && <p className="subscriptionPlanBoxSubheading">{plan.subType}</p>}
                        {plan.description && 
                        <div className="subscriptionPlanBoxDescription">{plan.description}</div>}
                        { plan.discount ? 
                         plan.discount ==="Custom" ?
                         <p className="subscriptionPricetxt"> Unlock Custom Price / Room + Taxes</p> :


                        <div>
                        <p className="strikedtxt">₹ {plan.pricePerRoom} / Room</p>
                         <p className="subscriptionPricetxt">₹ {handleDiscountedPrice(plan.pricePerRoom, plan.discount)} /  Room + Taxes</p>
                        </div>


                        :  <p className="subscriptionPricetxt">₹ {plan.pricePerRoom} / Room + Taxes</p>}
                       
                        
                        <div>
                          <p className="subscriptionPlanAbout">
                            {plan.discount   ? 
                            plan.discount ==="Custom" ?
                            <span>&#8226; <strong>{plan.discount} Discount</strong> </span>  :
                             <span>&#8226; Save <strong>{plan.discount}%</strong> </span>  : 
                            <span>&#8226; No Discount </span>}
                            <br></br>
                          </p>
                          
                        </div>
                        <div>
                        </div>
                        <div className="subscriptionChooseButtonContainer">
                        <button
                            className={selectedPlan === plan.subType ? 
                                      "subscriptionPlanChooseButton" :
                                      "subscriptionPlanChooseButton subscriptionPlanChooseButtonFadeOut"
                            }
                            onClick={(e) => handleChooseSubscription(e, plan)}
                        >
                    {/* {plan.discount && plan.discount ==="Custom" ?
                    "Contact Sales"
                    :
                    "Choose Plan" 
                    } */}
                    Choose Plan

                        </button>
                        </div>
                    </div>
                    ))}
                </div>

                <p className="subscriptionModelDisclaimer">* The prices listed are for each individual room on a monthly basis. This means that the amount specified is the cost to one room for a full month. If you subscribe for multiple rooms, the total cost will be adjusted accordingly. All Prices are exclusive of Taxes.</p>

                <div style={{paddingTop:20}} className="subscriptionCouponApplyContainer">
                 
                  
                  
                  <button
                    onClick={(e) => handleContinueButton(e)}
                    className="subscriptionContinueBtn"
                  >
                    
                    Contact Sales
                  </button>

                </div>
                <div>
                </div>
             
         
        </div>
        <div class="description">
          <div style={{paddingTop:50}}></div>

      <h4 className="subscriptionDescheading">Unlock the Full Potential of Your Hotel or Resort With BetterMyStay !!</h4>
      <section className="subscription-benefits-section">
            <h2 className="subscriptionDescSubheading">Subscription Benefits</h2>
            <div className="subscription-benefits">
            <p className="subs-text">As a Valued Subscriber to our BetterMyStay, you can expect a range of Benefits that will elevate your Hotel / Resort's operations to the Next Level. Our software is designed to provide a seamless experience for your Administrators, Staff, and Guests alike.</p>
            <ul>
              <li>
                <h3 className="subscriptionDescSubheading1">Better Stay Admin Panels</h3>
                <ul>
                <li className="subscription-benefits-list">
                  <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                
                  <span className="list-item">Streamlined Operations: Our Admin panels are designed to simplify your day-to-day operations, allowing you to manage your Hotel or Resort with Ease.</span>
                </li>   
                <li className="subscription-benefits-list">
                  <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                
                  <span className="list-item">Real-time Insights : Get Instant Access to key performance metrics and make data-driven decisions to drive Growth and Revenue.</span>
                </li> 
                {/* <li className="subscription-benefits-list">
                  <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                
                  <span className="list-item">Customizable Dashboard : Tailor your Admin Panels to fit your specific needs and Preferences.</span>
                </li>   */}
                <li className="subscription-benefits-list">
                  <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                  <span className="list-item">Promotion and Offer Management: Create, manage, and track promotions and offers across multiple channels, allowing you to drive revenue, increase customer engagement, and stay ahead of the competition.</span>
                </li>                
                <li className="subscription-benefits-list">
                  <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                
                  <span className="list-item">Centralized Service Management : Easily Monitor and Control all your services like Resturant, Spa and many more from a Single Dashboard, reducing the complexity of managing multiple systems.</span>
                </li> 
                <li className="subscription-benefits-list">
                  <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                
                  <span className="list-item">Staff Management : Assign tasks based on staff member department, availability, and priority, ensuring that the right person is assigned to the right task.</span>
                </li> 
                <li className="subscription-benefits-list">
                  <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                  <span className="list-item">Efficient Feedback Management : Collect, Track, and Respond to Customer Feedback in one place, enabling you to identify areas for improvement and enhance overall Customer Satisfaction.</span>
                </li>
                <li className="subscription-benefits-list">
                  <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                
                  <span className="list-item">Integration with PMS : Integrate our solution with your existing Property Management System (PMS) to synchronize data, automate workflows, and enhance Operational Efficiency and maximizing Productivity.</span>
                </li> 
            
               </ul>
              </li>
              <li>
              <h3 className="subscriptionDescSubheading1">Better Stay User App</h3>
              <ul>
                <li className="subscription-benefits-list">
                  <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                  <span className="list-item">Personalized Experience : Offer users a Personalized Experience, including Customized Offers, Promotions, and services tailored to their preferences and needs.</span>
                </li>
                <li className="subscription-benefits-list">
                  <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                  <span className="list-item">Real-time Order Booking and Cancellation : Allow users to Book and Cancel services in real-time, reducing wait times and Increasing Convenience.</span>
                </li>
                <li className="subscription-benefits-list">
                  <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                  <span className="list-item">Easy Access to Hotel Services : Provide users with easy access to Hotel Amenities, including Room Service, Housekeeping, and Maintenance requests.</span>
                </li>
                <li className="subscription-benefits-list">
                  <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                  <span className="list-item">Secure Payment Processing : Offer users a secure and convenient Ppayment Processing System, reducing the risk of fraud and Increasing User Trust.</span>
                </li>
                <li className="subscription-benefits-list">
                  <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                  <span className="list-item">Push Notifications and Reminders : Send users push notifications and reminders about their Bookings, Services, and Special Offers, keeping them engaged and informed.</span>
                </li>
                <li className="subscription-benefits-list">
                  <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                  <span className="list-item">Seamless Communication : Enable guests to communicate with your staff effortlessly, ensuring their needs are met and exceeded.</span>
                </li>
                <li className="subscription-benefits-list">
                  <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                  <span className="list-item">Increased Loyalty : Foster loyalty and encourage Repeat Business with our user-friendly app.</span>
                </li>
              </ul>
            </li>
        
        
              <li>
                  <h3 className="subscriptionDescSubheading1">Better Stay Staff App</h3>
                  <ul>
                    <li className="subscription-benefits-list">
                      <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                      <span className="list-item">Streamlined Task Management : Track, and Manage tasks efficiently, ensuring timely completion and reducing Manual Errors.</span>
                    </li>
                    <li className="subscription-benefits-list">
                      <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                      <span className="list-item">Real-time Communication : Enable seamless communication between Staff Members, ensuring that everyone is on the same page and reducing miscommunication.</span>
                    </li>
                    {/* <li className="subscription-benefits-list"> */}
                      {/* <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                      <span className="list-item">Role Based Task Assignment : Assign tasks based on staff member skills, availability, and priority, ensuring that the right person is assigned to the right task.</span>
                    </li> */}
                    <li className="subscription-benefits-list">
                      <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                      <span className="list-item">Efficient Time Management : Track staff work hours and breaks enabling you to optimize Staff Utilization and reduce labor costs.</span>
                    </li>
                    <li className="subscription-benefits-list">
                      <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                      <span className="list-item">Enhanced Customer Experience : Provide staff with the tools they need to deliver Exceptional Customer Service, resulting in increased Customer Satisfaction and Loyalty.</span>
                    </li>
                  </ul>


              </li>
            </ul>
            </div>
          </section>
          <section className="subscription-benefits-section">
            <h2 className="subscriptionDescSubheading">What Our Subscription Provides</h2>
            <div className="subscription-benefits">
            <ul>
              <li className="subscription-benefits-list">
                  <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                  <span className="list-item">Regular Software Updates : Stay ahead of the Curve with Regular Software Updates and new Feature Releases.</span>
              </li>
              <li className="subscription-benefits-list">
                <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                <span className="list-item">Dedicated Support : Our Dedicated Support team is always available to assist with any questions or issues.</span>
              </li>
              <li className="subscription-benefits-list">
                <div className="imageIcon"> <img style={{width : 16, height:16,paddingBottom:-15}} src={require('../assets/icons/check.png')} alt="Checkmark" /></div>
                <span className="list-item">Scalability : Our software is designed to Grow with your Business, ensuring that you can Scale Effortlessly.</span>
              </li>
            </ul>
            </div>
          </section>
        </div>
        <div style={{paddingTop:20}} className="subscriptionCouponApplyContainer">
           <button onClick={(e) => handleDemoButton(e)} className="subscriptionContinueBtn" > Book a Demo Now! </button>
        </div>
        </div>
        <Footer/>

        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "5rem" }}
      />
    </>
  
  );
};

export default Pricing;
