/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import "./container.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Contact from "../components/Contact";
import ContactPricing from "../components/ContactPricing";
import { useEffect } from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useLocation } from 'react-router-dom';


function ContactUs() {

  const location = useLocation();
  console.log(location.state)
  const { selectedPlanDetails } = location.state.selectedPlanDetails || "";
  const { flow } = location.state.flow || "";
  useEffect(() => {
    const element = document.documentElement;
    element.style.scrollBehavior = 'smooth';
    element.scrollTo(0, 0);
  }, []);



  
  return (
    <>

    <Header />
<div className="bg-contact-container">
  <div className="bg-contact-image">
    <div className="blurred-background">
    </div>
    {
      flow ==="Pricing" ? 
      <ContactPricing plan={{selectedPlanDetails}} /> 
      :
       <Contact /> 
    }
    
   
  </div>
</div>
<Footer />

    </>
  );
}


export default ContactUs;
