// eslint-disable-next-line no-unused-vars
import React from "react";
import Faqgenie from "../assets/images/Faqgenie.png";
import Faq from "react-faq-component";
import faqdata from "../assets/faqdata";
import { Link } from "react-scroll";
function Faqs() {
  const styles = {
    bgColor: "#f3e8cc05",
    titleTextColor: "#676464",
    rowTitleColor: "#676464",
    rowContentColor: "grey",
    arrowColor: "#676464",
  };
  return (
    <>
      <div className="xl:pt-16  xl:ml-4">
        <h4
          className="border-black text-black font-semibold text-lg  border-r-2 mr-2 inline sm:p-0  sm:mr-2 sm:ml-1
    sm:text-2xl 2xl:ml-8 "
        >
          <Link
            to="home"
            spy={true}
            smooth={true}
            offset={-50}
            duration={800}
            className=" text-black hover:text-white  hover:cursor-pointer  hover:rounded-2xl p-2 "
          >
            Home
          </Link>
        </h4>
        <h4 className="text-black inline font-semibold text-lg sm:text-2xl ">
          FAQs
        </h4>
        <h2 className="text-3xl pl-2 sm:text-6xl font-bold  sm:ml-0 sm:my-2 sm:pt-5 2xl:ml-7 ">
          FAQs
        </h2>
      </div>
      <div className="w-full pl-2 flex flex-col sm:flex-row ">
        <div className="w-full sm:w-3/5  sm:pt-5 sm:pr-7 xl:translate-x-11">
          <Faq
            className="w-full text-BMSGrey"
            data={faqdata}
            styles={styles}
          ></Faq>
        </div>
        <div className="sm:w-2/5 sm:mr-1 sm:ml-2 sm:translate-x-7 sm:-translate-y-1 ">
          <img src={Faqgenie} className="h-96 sm:h-80"></img>
        </div>
      </div>
    </>
  );
}

export default Faqs;
