/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import HomeCard from "../components/HomeCard";
import services from "../assets/services";
import "./home.css";
import MobileUI from "../assets/images/MobileUI.png";
import GenieDownload from "../assets/images/Genie.png";
import Scanner from "../assets/images/Scanner.png";
import Facebook from "../assets/icons/Facebook.svg";
import Insta from "../assets/icons/Insta.svg";
import Linkedin from "../assets/icons/Linkedin.svg";
import GooglePlay from "../assets/icons/google-play.svg";
import Appstore from "../assets/icons/apple.svg";
import Reviews from "../assets/Reviews";
import ReviewCard from "../components/ReviewCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import logo1 from "../assets/images/brandlogo/logo1.png";
import Faqs from "../components/Faqs";
import faqdata from "../assets/faqdata";
import Contact from "../components/Enquiry";
import { useEffect } from 'react';
import AboutUs from "../components/AboutUs";
import reviews from "../assets/Reviews";
import Header from "../components/Header";
import Footer from "../components/Footer";


function Home() {

  useEffect(() => {
    const element = document.documentElement;
    element.style.scrollBehavior = 'smooth';
    element.scrollTo(0, 0);
  }, []);
  
  const [counterOn, setCounterOn] = useState(false);
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div
        style={{
          backgroundColor: "#ffff",
          borderRadius: "10px",
          padding: "10px",
          position: "absolute",
          bottom: "32px",
        }}
      >
        <ul style={{ marginTop: "1px" }}> {dots} </ul>
      </div>
    ),
  };


  
  return (
    <>
    <Header/>
      <div className="main-container">
        {/*introsection*/}
        <section  
  
          id="home"
          className=" intro top-0 w-full relative "
          
        >
          <div
            className="absolute text-white sm:top-64 top-1/3 px-10 md:top-2/4 xl:top-1/3  2xl:1/3 xl:ml-20 xl:p-0
          2xl:left-14"
          >
            <h3 className="text-xl sm:text-2xl font-normal text-white">
              AWAY FROM MONOTONOUS LIFE
            </h3>
            <h1 className="text-3xl sm:text-5xl xl:text-7xl font-bold text-white">
              MAGICAL STAYS
            </h1>
            <p className="text-base sm:text-lg  font-medium">
              DISCOVER A NEW REALM OF HOSPITALITY WHERE TECHNOLOGY COMPLEMENTS
              LUXURY
            </p>
          </div>
          <div
            className="absolute text-white top-3/4 px-5  w-full flex justify-end md:top-3/5 md:px-2 md:translate-y-8 lg:top-96  xl:top-96 sm:top-96  xl:right-16 2xl:top-96 2xl:translate-y-20
          2xl:mr-16"
          >
            <ul className="space-y-2 md:pb-5 xl:space-y-8 flex-row">
              <li className="h-8 w-8 px-3 py-1 sm:h-12 sm:w-12 rounded-full bg-slate-50/50 sm:px-4 Sm:py-2 hover:bg-golden hover:shadow-all-slides  hover:shadow-gray-400/55">
                <a href="#">
                  {" "}
                  <img src={Facebook} className="h-6 sm:h-10"></img>
                </a>
              </li>

              <li 
                className="h-8 w-8 px-2 py-1  sm:h-12 sm:w-12 sm:px-3 sm:py-1 rounded-full bg-slate-50/50 hover:bg-golden hover:shadow-all-slides  hover:shadow-gray-400/55"
              >
                <a href="#">
                  {" "}
                  <img src={Insta} className=" h-6 sm:h-10"></img>
                </a>
              </li>

              <li className="h-8 w-8  px-2   sm:h-12 sm:w-12 sm:px-3 sm:py-1 hover:bg-golden rounded-full bg-slate-50/50 hover:shadow-all-slides  hover:shadow-gray-400/55">
                <a href="#">
                  <img src={Linkedin} className="h-10 pb-2 xl:pb-0"></img>
                </a>  
  
              </li>
            </ul>
          </div>
        </section>
        {/*section 1 */}
        <section
          id="services"
          className="relative section1 bg-white text-center w-full "
        >
          <h1 className="pt-14 pb-5 text-BMSBlue  text-lg sm:text-2xl xl:text-3xl font-semibold">
            <hr className="inline-block align-middle w-10 h-1 bg-BMSBlue"></hr>
            WHAT WE OFFER
            <hr className="inline-block align-middle w-10 h-1 bg-BMSBlue"></hr>
          </h1>
          <h2 className="text-BMSGrey text-3xl  sm:text-6xl font-semibold pb-5">
            Our Services
          </h2>
          <br/>
          <br/>
          <div className="flex items-center flex-col space-y-8 p-3  md:flex-row sm:space-y-8 sm:items-center sm:justify-center md:space-y-0   md:space-x-6 lg:space-x-8 lg:px-14 ">
            {services.map(({ id, img, heading, Desc }) => (
              <HomeCard
                key={id}
                image={img}
                heading={heading}
                description={Desc}
              />
            ))}
          </div>
          <br/>
          <ScrollTrigger
            onEnter={() => setCounterOn(true)}
            onExit={() => setCounterOn(false)}
            className="w-full"
          >
            {/* <div className="w-full text-center h-20 text-BMSBlue ">
              <h2
                className="text-lg my-4 m-7
              font-semibold sm:m-10 sm:my-4 sm:text-3xl xl:text-4xl xl:font-semibold xl:m-10 xl:my-4"
              >
                Trusted by{" "}
                {counterOn && (
                  <CountUp start={0} end={20} duration={1} delay={0} />
                )}
                + Hotels
              </h2>  
  
            </div> */}
          </ScrollTrigger>
        </section>
        {/*section 2 */}

        {/*<section className="section2 bg-white text-center  overflow-hidden relative ">
          <ScrollTrigger
            onEnter={() => setCounterOn(true)}
            onExit={() => setCounterOn(false)}
          >
            <h1 className="text-BMSBlue sm:text-6xl font-semibold sm:mt-24 sm:mb-32 text-2xl mt-16 mb-16">
              Trusted by{" "}
              {counterOn && (
                <CountUp start={0} end={100} duration={1} delay={0} />
              )}
              + hotels
            </h1>
            <div className="logos">
              <div className="logos-slide inline-block ">
                <div className="flex">
                  <img src={logo1} />  useEffect(() => {
    const element = document.documentElement;
    element.style.scrollBehavior = 'smooth';
    element.scrollTo(0, 0);
  }, []);
  
                  <img src={logo5} />
                  <img src={logo1} />
                  <img src={logo5} /useEffect(() => {
    const element = document.documentElement;
    element.style.scrollBehavior = 'smooth';
    element.scrollTo(0, 0);
  }, []);>
                  <img src={logo1} />
                  <img src={logo5} />
                  <img src={logo1} />
                </div>
              </div>

              <div className="logos-slide ">
                <div className="flex">
                  <img src={logo5} />
                  <img src={logo1} />
                  <img src={logo5} />
                  <img src={logo1} />
                  <img src={logo5} />
                  <img src={logo1} />
                  <img src={logo5} />
                </div>
              </div>
            </div>
          </ScrollTrigger>
        </section>
            */}
        {/*section 3 */}
        <section className=" section3 bg-BMSBlue text-center flex-col download flex items-center justify-center md:flex-row   ">
   
          <div className=" h-1/3 w-full md:h-full md:w-1/3 flex flex-row justify-center md:items-center">
            <div className="mt-4 translate-x-3 z-40 sm:mt-1">
              <img
                src={GenieDownload}
                className=" h-44 sm:h-52 md:h-80 xl:h-96 2xl:h-genie lg:h-96"
              ></img>
            </div>
            <div className="mt-9 -translate-x-2 z-30 sm:mt-4">
              <img
                src={MobileUI}
                className="h-36  sm:h-48 md:h-64 lg:h-80 md:w-36  xl:p-0 2xl:h-mobile 2xl:w-48 xl:h-80   "
              ></img>
            </div>
          </div>
        
         <div className=" h-1/3 w-full md:h-full   md:w-1/3   ">
            <div className="p-3  flex flex-col w-full items-center justify-center text-center  md:h-full"> 
              <h1 className=" font-bold text-BMSBlue    px-3 py-2 rounded-2xl sm:text-xl xl:text-3xl xl:-mt-16 2xl:text-4xl 2xl:-translate-y-16">
                {/* Soon Available On */}
              </h1>
                {/* <div className="h-10  mt-3 mb-2 sm:mt-5 sm:mb-3 xl:mt-10 xl:mb-8">
                  <a
                    href="#"
                    className="flex w-36  rounded-3xl p-2 app-button 
                          items-center justify-center bg-white text-black font-bold sm:w-44  sm:py-3 sm:px-2 lg:w-44 xl:w-64  xl:-m-5  border-white hover-effect"
                  >
                    <img
                      src={GooglePlay}
                      alt="Play Store Icon"
                      className="h-4 2xl:mr-12 2xl:h-10  "
                    />
                    <div className="flex flex-col ml-3 lg:ml-1">
                      <span className="text-left text-black font-thin text-xs sm:text-base xl:text-base xl:font-semibold">
                        Get it on
                      </span>
                      <span className="font-bold text-black text-xs sm:text-base xl:text-lg">
                        Play Store
                      </span>
                    </div>
                  </a>
                </div>
              
                <div className="h-12 mt-3 sm:mt-9 " style={{paddingTop:15}}>
                  <a
                    href="#"
                    className="flex w-36  rounded-3xl p-2 sm:w-44 lg:w-44 xl:w-64
                    items-center justify-center bg-white  text-black font-bold !important sm:py-3 sm:px-2  xl:-m-5  border-white"
                  >
                    <img
                      src={Appstore}
                      alt="Play Store Icon"
                      className="h-4 2xl:mr-12 2xl:h-10 "
                    />
                    <div className="flex flex-col ml-3 md:ml-5">
                      <span className="text-left text-black font-thin text-xs sm:text-base xl:text-base xl:font-semibold ">
                        Get it on
                      </span>
                      <span className="font-bold text-black text-xs sm:text-base xl:text-lg ">
                        {" "}
                        App Store
                      </span>
                    </div>
                  </a>
                </div> */}
            </div>
          </div>

          <div className="text-center align-middle justify-center flex  w-full h-1/3  sm:px-7 md:px-1 md:items-center md:relative md:h-full md:w-1/3 ">
            <p
              className="text-BMSBlue  bg-white rounded-xl mt-4 mb-4 mx-4 text-xs h-40 p-3
            sm:mt-12 sm:text-base md:h-72 md:text-sm lg:text-base lg:mt-16    lg:p-5 lg:h-80 lg:w-full  xl:text-lg 2xl:text-2xl 2xl:h-4/6 2xl:pt-16"
             style={{ textAlign: 'justify',margin:10 }}>

              At BetterMyStay, we&apos;re revolutionizing the hospitality industry. 
              Our Mobile App offers a seamless, personalized experience for hotel guests during their stay. 
              Leveraging cutting-edge technology, we&apos;re making it easier than ever to find the perfect stay 
              and enjoy a hassle-free travel experience.From Checking In to Checking 
              Out, our cutting-edge technology ensures a hassle-free journey. 
              Discover a new level of convenience and comfort with BetterMyStay.
              
              
            </p>
          </div>
        </section>
        {/*Section 4*/}
        <section
          className="section4 bg-white  text-center w-full sm:overflow-hidden  md:mt-10 "
          id="review"
        >
          <h1 className="mt-6 text-BMSBlue text-3xl sm:text-4xl font-semibold p-2 2xl:mt-9 xl:mt-10">
            What our Customers are Saying !!!
          </h1>
          <div className="w-full h-3/4 flex justify-center items-center">
            <Slider {...settings}>
              {Reviews.map(({ id, Profile, Name, Comment, rating }) => (
                <ReviewCard
                  key={id}
                  image={Profile}
                  name={Name}
                  comment={Comment}
                  rating={rating}
                />
              ))}
            </Slider>
          </div>
        </section>
        {/*section 5*/}
        <section id="faq" className="section5 ">
          <div className="sm:h-full sm:p-4 md:h-96 m-4 md:px-16">
            <Faqs />
          </div>
        </section>
        <section className="section6">
          <Contact></Contact>
        </section>
      </div>
      <Footer/>
    </>
  );
}

export default Home;
