/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Menu, X } from "lucide-react";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./nav.module.css";

function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  const handleCLick = () => {};
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <nav className="w-12 px-3 mr-2 flex  justify-end  rounded-3xl text-white sm:w-1/6 sm:px-10  sm:m-3  sm:py-2 sm:mx-10  md:border md:mr-12  md:w-8/12 md:mx-1 md:px-2 md:py-1 md:border-white  lg:w-8/12 xl:pt-2 xl:mr-16 2xl:mr-32 2xl:py-2">
        <div className="hidden w-full justify-between md:flex ">
          <NavLinks></NavLinks>
        </div>
        <div
          className=" z-40   sm:translate-x-12
        sm:translate-y-1 md:hidden"
        >
          <button onClick={toggleNavbar}>{isOpen ? <X /> : <Menu />}</button>
        </div>
        {isOpen && (
          <div className=" flex flex-col w-32 top-0 items-center basis-full   bg-gray-400/60 font-semibold p-2 text-sm absolute right-1 pr-4 pt-10 h-72 space-y-10  rounded-3xl">
            <NavLinks />
          </div>
        )}
      </nav>
    </>
  );
}
const NavLinks = () => {
  const scrollDuration = 800;
  return (
    <>
      <div className="flex flex-col space-y-3 md:space-y-0 md:space-x-3 md:flex-row xl:space-x-7  ">
        <NavLink
          to="/bmshm#home"
          spy="true"
          smooth="true"
          offset={-100}
          duration={scrollDuration}
          className="hover:text-white hover:bg-golden hover:rounded-2xl p-1 px-3"
        >
          HOME
        </NavLink>
        <HashLink
          smooth
          to="/bmshm#services"
          scroll={(el) =>
            el.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            })
          }
          className="hover:text-white hover:bg-golden hover:rounded-2xl p-1 px-3"
        >
          SERVICES
        </HashLink>
        <HashLink
          smooth
          to="/bmshmreview"
          scroll={(el) =>
            el.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            })
          }
          className="hover:text-white hover:bg-golden hover:rounded-2xl p-1 px-3"
        >
          REVIEW
        </HashLink>
        
        <HashLink
          smooth
          to="/bmshm#enquiries"
          scroll={(el) =>
            el.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            })
          }
          className="hover:text-white hover:bg-golden hover:rounded-2xl p-1 px-3"
        >
          ENQUIRIES
        </HashLink> 
           <HashLink
          smooth
          to="/bmshm/ContactUs"
          scroll={(el) =>
            el.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            })
          }
          className="hover:text-white hover:bg-golden hover:rounded-2xl p-1 px-3"
        >
          CONTACT US
        </HashLink>

        <HashLink
          smooth
          to="/bmshm/Pricing"
          scroll={(el) =>
            el.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            })
          }
          className="hover:text-white hover:bg-golden hover:rounded-2xl p-1 px-3"
        >
          PRICING
        </HashLink>
      </div>

      {/* <div>
        <button className="bg-white ml-2 p-1 text-xs text-golden rounded-2xl  hover:bg-golden hover:text-white md:py-2 md:px-1   md:text-xs md:ml-0 lg:px-3 2xl:text-sm">
          SIGN IN OR REGISTER
        </button>
      </div> */}
    </>
  );
};
export default Nav;
