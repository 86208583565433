import smartphone from "./icons/Servicesicons/faceid.png";
import app from "./icons/Servicesicons/app.png";
import payment from "./icons/Servicesicons/payment.png";
import checkout from "./icons/Servicesicons/checkout.png";
import route from "./icons/Servicesicons/route.png";

const services = [
  {
    id: 1,
    img: smartphone,
    heading: "Sleamless CheckIn's",
    Desc: "\nSecure KYC verification and Check-In by just verifying your Selfie",
  },
  {
    id: 2,
    img: app,
    heading: "In-App Services",
    Desc: `\nConvenient Food Ordering Anytime, Spa Bookings and House Keeping services Anywhere on the premises.`,
  },
  {
    id: 3,
    img: checkout,
    heading: "Sleamless CheckOut's",
    Desc: "\nRaise Check Out requests with just a click of a button",
  },
  {
    id: 4,
    img: payment,
    heading: "Integrated Payment Solutions",
    Desc: "Pay all bills via the BetterMyStay App Securely.",
  },
  {
    id: 5,
    img: route,
    heading: "End to End Software for Hotels",
    Desc: "Manage all Guest and Service requests via the BetterMyStay Software",
  },
];
export default services;
